<template>
   <div class="col-12">
      <div class="card mb-2" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row">
               <div class="col-12">
                  <div class="form-check">
                     <input class="form-check-input" type="checkbox" :id="'modulo'+ modulo.id" @click="toggle" :checked="modulo.habilitado" v-model="modulo.habilitado">
                     <label class="form-check-label d-flex" :for="'modulo'+ modulo.id">
                        <div class="w-max-content ps-2 pe-12">
                           <img :src="modulo.icone == null ? '' : modulo.icone" class="rounded" alt="icon" @error="imageError" height="35" />
                        </div>
                        <div class="col align-self-center">
                           <h1 class="font-14 mb-0 limitador-1 text-uppercase">{{ modulo.nome == null ? 'Sem nome' : modulo.nome }}</h1>
                        </div>
                     </label>
                  </div>
               </div>

               <div class="col-12 px-0 mt-1">
                  <div class="row">
                     <role v-for="(role, index) in modulo.gerencialRoles" :key="index" :role="role" :moduloHabilitado="modulo.habilitado" />
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import role from '@/components/usuarios/CheckRole.vue'

export default {
	name: 'CheckModulo',
   props: ['modulo', 'index'],
   components: {
      role
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      toggle : function () {
         this.$emit('toggle', this.modulo.id)
      }
   }
}

</script>

<style scoped>

.form-check {
	margin-bottom: 0;
	min-height: unset;
}

.form-check-input {
	cursor: pointer;
	border-radius: 4px !important;	
	transform: translateY(8px);
}

.form-check-label {
	cursor: pointer;
	display: block;
	user-select: none;
}

</style>